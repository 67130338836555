/*Font Family*/
@font-face{
    font-family:'TiemposText';
    src:url('../fonts/TiemposText-Bold.eot');
    src:url('../fonts/TiemposText-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TiemposText-Bold.woff2') format('woff2'),
        url('../fonts/TiemposText-Bold.woff') format('woff'),
        url('../fonts/TiemposText-Bold.ttf') format('truetype'),
        url('../fonts/TiemposText-Bold.svg#TiemposText-Bold') format('svg');
    font-weight:bold;
    font-style:normal;
}
@font-face{
    font-family:'TiemposText';
    src:url('../fonts/TiemposText-Regular.eot');
    src:url('../fonts/TiemposText-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/TiemposText-Regular.woff2') format('woff2'),
        url('../fonts/TiemposText-Regular.woff') format('woff'),
        url('../fonts/TiemposText-Regular.ttf') format('truetype'),
        url('../fonts/TiemposText-Regular.svg#TiemposText-Regular') format('svg');
    font-weight:normal;
    font-style:normal;
}

@font-face{
    font-family:'Calibre';
    src:url('../fonts/Calibre-Bold.eot');
    src:url('../fonts/Calibre-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibre-Bold.woff2') format('woff2'),
        url('../fonts/Calibre-Bold.woff') format('woff'),
        url('../fonts/Calibre-Bold.ttf') format('truetype'),
        url('../fonts/Calibre-Bold.svg#Calibre-Bold') format('svg');
    font-weight:bold;
    font-style:normal;
}

@font-face{
    font-family:'Calibre';
    src:url('../fonts/Calibre-Regular.eot');
    src:url('../fonts/Calibre-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Calibre-Regular.woff2') format('woff2'),
        url('../fonts/Calibre-Regular.woff') format('woff'),
        url('../fonts/Calibre-Regular.ttf') format('truetype'),
        url('../fonts/Calibre-Regular.svg#Calibre-Regular') format('svg');
    font-weight:normal;
    font-style:normal;
}

@font-face{
    font-family:'icomoon';
    src:url('../fonts/icomoon.eot?7a9nmf');
    src:url('../fonts/icomoon.eot?7a9nmf#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?7a9nmf') format('truetype'),
        url('../fonts/icomoon.woff?7a9nmf') format('woff'),
        url('../fonts/icomoon.svg?7a9nmf#icomoon') format('svg');
    font-weight:normal;
    font-style:normal;
    font-display:block;
}

[class^="icon-"], [class*=" icon-"]{
    font-family:'icomoon' !important;
    speak:none;
    font-style:normal;
    font-weight:normal;
    font-variant:normal;
    text-transform:none;
    line-height:1;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
}

.icon-send-template:before{content:"\e900";}
.icon-plus:before{content:"\e901";}
.icon-pediatric:before{content:"\e902";}
.icon-mail:before{content:"\e903";}
.icon-gyn:before{content:"\e904";}
.icon-gi:before{content:"\e905";}
.icon-general-plus:before{content:"\e906";}
.icon-general:before{content:"\e907";}
.icon-summary-graph:before{content:"\e908";}
.icon-stakeholders:before{content:"\e909";}
.icon-calendar:before{content:"\e90a";}
.icon-checklist-doc:before{content:"\e90b";}
.icon-checklist:before{content:"\e90c";}
.icon-colorectal:before{content:"\e90d";}
.icon-competitive-collateral:before{content:"\e90e";}
.icon-competitive:before{content:"\e90f";}
.icon-cv:before{content:"\e910";}
.icon-doc:before{content:"\e911";}
.icon-ent-cases:before{content:"\e912";}
.icon-ent:before{content:"\e913";}
.icon-equipment-box:before{content:"\e914";}
.icon-evaluations:before{content:"\e915";}
.icon-ortho:before{content:"\e916";}
.icon-evaluations-search:before{content:"\e917";}
.icon-sv-cases:before{content:"\e918";}
.icon-market-insights:before{content:"\e919";}
.icon-collateral:before{content:"\e91a";}
.icon-talk-speaker:before{content:"\e91b";}
.icon-talk-tracks:before{content:"\e91c";}
.icon-trials:before{content:"\e91d";}
.icon-uro:before{content:"\e91e";}
.icon-summary:before{content:"\e91f";}
.icon-bariatric:before{content:"\e920";}
.icon-trauma:before{content:"\e921";}
.icon-breast-recon:before{content:"\e922";}
.icon-onc:before{content:"\e923";}
.icon-note:before{content:"\e924";}
.icon-folder:before{content:"\e925";}
.icon-logos:before{content:"\e926";}
.icon-surgeons:before{content:"\e927";}
.icon-video:before{content:"\e928";}
.icon-skull:before{content:"\e929";}
.icon-related-doc:before{content:"\e92a";}
.icon-mail-open:before{content:"\e92b";}
.icon-people:before{content:"\e92c";}
.icon-ent-old:before{content:"\e92d";}
.icon-calendar-new:before{content:"\e92e";}
.icon-camera:before{content:"\e92f";}
.icon-checklists:before{content:"\e930";}
.icon-marketinsights:before{content:"\e931";}
.icon-collateral-new:before{content:"\e932";}
.icon-evaluations-left:before{content:"\e933";}
.icon-gi-old:before{content:"\e934";}
.icon-uro-old:before{content:"\e935";}
.icon-gyn-onc:before{content:"\e936";}
.icon-pediatric-old:before{content:"\e937";}
.icon-bariatric-new:before{content:"\e938";}
.icon-colorectal-old:before{content:"\e939";}
.icon-cases:before{content:"\e93a";}
.icon-other:before{content:"\e93b";}
.icon-delete:before{content:"\e93c";}
.icon-edit-genral:before{content:"\e93d";}
.icon-notes-pen:before{content:"\e93e";}
.icon-user-small:before{content:"\e93f";}
.icon-call:before{content:"\e940";}
.icon-play-right:before{content:"\e941";}
.icon-msg:before{content:"\e942";}
.icon-comment:before{content:"\e943";}
.icon-competitive-new:before{content:"\e944";}
.icon-heart:before{content:"\e945";}
.icon-general-old:before{content:"\e946";}
.icon-done:before{content:"\e947";}
.icon-email:before{content:"\e948";}
.icon-photo:before{content:"\e949";}
.icon-ortho-new:before{content:"\e94a";}
.icon-evaluations-right:before{content:"\e94b";}
.icon-executive-summary-big:before{content:"\e94c";}
.icon-executive-summary-small:before{content:"\e94d";}
.icon-logo:before{content:"\e94e";}
.icon-gyn-old:before{content:"\e94f";}
.icon-facility-photo:before{content:"\e950";}
.icon-inventory-management:before{content:"\e951";}
.icon-logosncontent:before{content:"\e952";}
.icon-annual-report:before{content:"\e953";}
.icon-practice-logos:before{content:"\e954";}
.icon-edit-new:before{content:"\e955";}
.icon-fill-them:before{content:"\e956";}
.icon-in-service:before{content:"\e957";}
.icon-create-new:before{content:"\e958";}
.icon-clock:before{content:"\e959";}
.icon-email-draft:before{content:"\e95a";}
.icon-search:before{content:"\e95b";}
.icon-notes:before{content:"\e95c";}
.icon-forward:before{content:"\e95d";}
.icon-undefined:before{content:"\e95e";}
.icon-add-item:before{content:"\e95f";}
.icon-upload-file:before{content:"\e960";}
.icon-answer-choice:before{content:"\e961";}
.icon-play-btn:before{content:"\e962";}
.icon-quotes:before{content:"\e963";}
.icon-related-trials:before{content:"\e964";}
.icon-send-templates:before{content:"\e965";}
.icon-skull-new:before{content:"\e966";}
.icon-stakeholders-old:before{content:"\e967";}
.icon-surgeons-new:before{content:"\e968";}
.icon-surveys:before{content:"\e969";}
.icon-talktracks:before{content:"\e96a";}
.icon-team:before{content:"\e96b";}
.icon-trial-summary:before{content:"\e96c";}
.icon-user:before{content:"\e96d";}
.icon-lock:before{content:"\e96e";}  

/*html,body,main{height:100%;}*/
body{font-family:'Calibre';font-weight:normal;color:#424242;}

/*Golabal css*/
a:focus{outline:none !important;}
a:focus, a:hover{outline:none;text-decoration:none;outline:none !important;}
ul{list-style:none;}
.align-middle{padding-top:6px;display:block;}
nav>li>a:focus, .nav>li>a:hover{text-decoration:none;background-color:transparent;}
.form-control:focus{border-color:#54575a;outline:0;-webkit-box-shadow:none;box-shadow:none;}
*:focus{outline:none !important;}

.container{max-width:100%;}
@media screen and (min-width:1600px){.container{width:1320px;}}
@media (min-width:1900px){.container{width:1610px;}}

.text-success{color:#00cb13 !important;}
.text-danger{color:#b90000 !important;}

/*ellipsis*/
.ellipsis{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}

/*Progress bar*/
.progress{height:4px;margin-bottom:0;background-color:transparent;border-radius:0px;-webkit-box-shadow:none;box-shadow:none;position:absolute;left:0;bottom:0;right:0;}

/* btn style*/
.btn-rounded{width:30px;height:30px;border-radius:50%;font-size:18px;padding-left:0;text-align:center;line-height:30px;padding:0;text-align:center;}
.btn-default{color:#424242;background-color:#fff;border-color:#424242;font-size:16px;line-height:1;opacity:1; padding:6px 40px;letter-spacing:0.8px;color:#424242;}
.action-row  button[disabled]{opacity:0.3;}
.action-row .btn-default{font-size:12px;border-radius:4px;padding:8px 15px 6px;letter-spacing:0.5px;margin-right:15px;}
.btn-primary{color:#fff;background-color:#009ddc;border-color:#009ddc;letter-spacing:0.8px;}
.btn-default.focus, .btn-default:focus,.btn-default:active,.btn-default:hover,.btn-default:focus.btn-default.active.focus, .btn-default.active:focus, .btn-default.active:hover, .btn-default:active.focus, .btn-default:active:focus, .btn-default:active:hover, .open>.dropdown-toggle.btn-default.focus{color:#424242;background-color:transparent;border-color:#424242;}
.btn.active, .btn:active,.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus{box-shadow:none;outline:none;}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover{background-color:#14b3f3;border-color:#14b3f3;}
.save-btn{color:#fff;background:#009ddc;border-color:#009ddc;border-radius:4px;text-transform:uppercase;font-size:19px;padding:8px 40px 6px;}
.save-btn:hover,.action-row .save-btn:focus{color:#009ddc;background:#fff;border-color:#009ddc;}
.black-btn{color:#fff;background:#424242;border-color:#424242;border-radius:4px;text-transform:uppercase;font-size:19px;padding:8px 40px 6px;}
.black-btn:hover, .black-btn.focus, .black-btn:active:focus{color:#424242;background:#fff;border-color:#424242;}
.btn-border{padding:8px 40px 6px;font-size:19px;border-radius:9px;}
.blue-btn{background:#009ddc;}
.blue-btn:hover,.blue-btn:focus{color:#009ddc;border-color:#009ddc;}
.import-btn{letter-spacing:0.80px;margin-bottom:0;margin-top:15px;padding:10px 25px 6px;font-size:12px;line-height:16px;border-radius:4px;display:inline-block;width:auto;}
.red-btn{background:#b90000;color:#fff; border-radius:9px;text-transform:uppercase;font-size:19px;padding:8px 40px 6px;border-color:#b90000;}
.red-btn:hover,.red-btn:focus{color:#b90000;border-color:#b90000;}
.white-btn{background:none;color:#fff;border-radius:0px;text-transform:capitalize;font-size:19px;padding:30px 40px 16px;border-color:#fff;line-height:1;border-width:3px;}
.white-btn:hover,.white-btn:focus,.white-btn.active.focus, .white-btn.active:focus, .white-btn.active:hover, .white-btn:active.focus, .white-btn:active:focus, .white-btn:active:hover{color:#424242;border-color:#ffff;background:#fff}
/*Table style*/
.table>thead>tr>th{border-bottom:2px solid #54575a;position:relative;font-weight:normal;padding:10px 15px 5px 15px;text-transform:uppercase;}
.table>thead>tr>td{padding:12px;}
.table>thead  th  .th-name{position:relative;padding-right:18px;}
.table>thead th .bottom-border{padding-bottom:7px;position:relative;}
.table>thead th .bottom-border:before{content:'';position:absolute;left:0;right:11px;height:2px;background:#009ddc;bottom:1px;margin:auto;}
.table>thead th .th-name:after{content:'\f0d7';font-family:'FontAwesome';font-size:14px;display:inline-block;margin-left:6px;font-weight:normal;vertical-align:middle;position:absolute;margin-top:-2px;right:0;}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{color:#54575a;font-size:16px;vertical-align:middle;}
.table>tbody>tr>td{border-top:1px solid #e1e1e1; padding:3px 15px;vertical-align:middle;line-height:1;}
.table>tbody>tr:first-child>td{border-top:none;}
.table>tbody>tr.active>td{background:#F1F1F1;}

/*checkbox*/
.custom-checkbox [type="checkbox"]{opacity:0;position:absolute;width:41px;height:41px;bottom:0;top:0;margin:auto;} 
.profile-pic span{width:30px;height:30px;color:#fff;display:inline-block;border-radius:100%;font-size:16px;font-weight:bold;text-align:center;text-transform:uppercase;vertical-align:middle;padding:10px 0;}
.custom-checkbox{width:22px;position:relative;}
.custom-checkbox label{height:22px;width:22px;margin:0;}
.custom-checkbox [type="checkbox"] + label:before{content:'';font-size:12px;width:22px;height:22px;position:absolute;text-align:center;line-height:24px;border-radius:100%;top:0;margin:auto;bottom:0;-moz-box-shadow:rgba(0, 0, 0, 0.20) 0 0 4px 1px inset;-webkit-box-shadow:rgba(0, 0, 0, 0.20) 0 0 4px 1px inset;box-shadow:rgba(0, 0, 0, 0.20) 0 0 4px 1px inset;display:block;cursor:pointer;}
.custom-checkbox [type="checkbox"]:checked + label:before{background:#009ddc;color:#fff;content:'\f17b';font-family:LineAwesome;text-align:center;}

/*custom scrollbar*/
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail{background-color:transparent;}
.mCSB_scrollTools .mCSB_draggerRail,.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{width:8px;}
.mCSB_inside>.mCSB_container{margin-right:0;}

/*Modal css*/
@media (min-width:768px){
.modal-dialog{width:400px;}
.modal:before{display:inline-block;vertical-align:middle;content:" ";height:100%;}
}

.modal-dialog{display:inline-block;text-align:left;vertical-align:middle}
.modal{text-align:center}
.modal-content{-webkit-box-shadow:0 0 39px 19px rgba(0,0,0,0.2);box-shadow:0 19px 39px 0 rgba(0,0,0,0.2);border-radius:0px;border:none;}
.modal-header{border:none;padding:30px 30px 20px}
.modal-footer{border:none;padding:0 30px 30px}
.modal-body .modal-footer{padding:0 0 30px;}
.modal-body{padding:0 30px}
.modal-title{font-size:30px;line-height:34px;font-weight:bold;letter-spacing:0.2px;color:#000;}
.form-container .form-group label{color:#bbbbbb;font-size:16px;line-height:18px;font-weight:normal;text-transform:uppercase;}
.form-container .form-group .form-control{border:none;border-bottom:1px solid #bbb;border-radius:0px;padding-bottom:0;padding-left:0;padding-top:0;font-size:16px;color:#000;font-family:'TiemposText';-webkit-box-shadow:none;box-shadow:none;background:none;height:26px}
.form-container .form-group{margin-bottom:25px}
.form-container .form-group .remain_char{font-size:14px;margin-top:5px}
.form-control::-webkit-input-placeholder{color:#bbb;font-size:20px;opacity:0.8;font-family:'TiemposText';}
.form-control::-moz-placeholder{color:#bbb;font-size:20px;opacity:0.8;font-family:'TiemposText';}
.form-control:-ms-input-placeholder{color:#bbb;font-size:20px;opacity:0.8;font-family:'TiemposText';}
.form-control:-moz-placeholder{color:#bbb;font-size:20px;opacity:0.8;font-family:'TiemposText';}
.modal-footer .btn-default{float:left;font-size:14px;padding:11px 25px 5px;line-height:16px;text-transform:uppercase;letter-spacing:0.8px;border-color:#424242;border-width:1px;-webkit-border-radius:4px;border-radius:4px}
.modal-footer .btn-default:hover,.modal-footer .btn-default:focus{background:#424242;color:#fff}
.modal-footer .btn-primary{float:right;font-size:14px;padding:11px 25px 5px;line-height:16px;text-transform:uppercase;letter-spacing:0.8px;color:#fff;background:#009ddc;border:1px solid transparent;-webkit-border-radius:4px;border-radius:4px;}
 .modal-footer .btn-primary[disabled]{background-color:#bbb;border-color:#bbb;}
.modal .btn-primary:hover,.modal .btn-primary:focus.modal .btn-primary:active:focus, .modal .btn-primary:active:hover{background:#fff;border:1.5px solid #424242;color:#424242;}
.modal .blue-btn{background:#009ddc;}
.modal .blue-btn:hover,.modal .blue-btn:focus.modal .blue-btn:active:focus, .modal .blue-btn:active:hover{color:#009ddc;border-color:#009ddc;}

/*Sidebar*/
.sidebar{float:left;width:375px;background:#54575a;background:-moz-linear-gradient(top, #54575a 0%, #000000 100%);background:-webkit-linear-gradient(top, #54575a 0%, #000000 100%);background:linear-gradient(to bottom, #54575a 0%,#000000 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#54575a', endColorstr='#000000',GradientType=0 );position:fixed;height:100%;z-index:999;-webkit-transition:all 0.35s ease 0s;transition:all 0.35s ease 0s;transform:translateX(-100%);-webkit-transform:translateX(-100%);left:0}
.sidebar-menu{max-height:-webkit-calc(100vh - 127px);max-height:-moz-calc(100vh - 127px);max-height:calc(100vh - 127px);overflow:auto}

/* Vertical menu that slides from the left or right */
/* .cbp-spmenu-left{left:-9999px;} */
.cbp-spmenu-left.cbp-spmenu-open{left:0px;-webkit-transition:all 0.35s ease 0s;transition:all 0.35s ease 0s;transform:translateX(0);-webkit-transform:translateX(0)}
.cbp-spmenu-open:before{content:'';position:fixed;left:375px;right:0;top:0;bottom:0;background:rgba(255,255,255,0.5);z-index:-1}
main#root{-webkit-transition:all 0.35s ease 0s;transition:all 0.35s ease 0s}
body.menu-open main#root{margin-left:375px}
body.menu-open footer#stickyFooter,body.menu-open header.sticky-header{left:375px}
body.menu-open header .header-left .header_sidebar_toggle{opacity:0;width:0;overflow:hidden;padding:0}
body.menu-open header .header-left .header_sidebar_toggle i.la-bars:before{content:"\F191"}

/* Transitions */
.cbp-spmenu{-webkit-transition:all 0.3s ease;-moz-transition:all 0.3s ease;transition:all 0.3s ease;}
.sidebar-top{padding:12px 30px 12px 30px;border-bottom:1px solid #e1e1e1;text-align:center;position:relative;}
.sidebar-toggle .la-bars{font-size:24px}
.sidebar-toggle .la-angle-left{font-size:18px;margin-right:-1px;}
.sidebar-toggle{color:#fff;float:left;left:20px;position:absolute;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);top:50%;transform:translateY(-50%);}
.logo-sidebar img{display:block;height:35px;}
.sidebar-toggle i{vertical-align:middle;}
.sidebar-menu li a, .sidebar-menu .parent>span{font-size:14px;display:block;color:#fff;border-bottom:1px solid #e1e1e1;border-right:4px solid transparent;padding:13px 20px 10px;position:relative;}
.sidebar-menu li.parent > a, .sidebar-menu .parent>span{font-size:18px;font-weight:bold;} 
.sidebar-menu > ul{padding:0;} 
.sidebar-menu li i{margin-right:12px;}
.sidebar-top a:focus,.sidebar-top a:hover{color:#fff;}
/*.sidebar-menu li.parent > a span:after,.sidebar-menu .parent>span:after{display:block;content:'\f0d7';font-family:'FontAwesome';font-size:16px;display:inline-block;margin-left:6px;font-weight:normal;vertical-align:middle;position:absolute;line-height:1;margin-top:6px;}*/
.sidebar-menu .parent > span i.fa{font-size:12px;display:inline-block;margin-left:7px;font-weight:normal;vertical-align:middle;position:absolute;line-height:1;margin-top:6px}
.sidebar-menu .parent > span.open_menu i.fa:before{content:'\f0da';}
.sidebar-menu .children li a{padding:13px 0 10px;letter-spacing:1px;}
.sidebar-menu .children li i{margin-right:9px;}
.logo-sidebar{display:inline-block;vertical-align:middle;}
.sidebar-menu li.active > a,.sidebar-menu li:hover > a{border-right:4px solid #009ddc;color:#009ddc;}
.sidebar-menu li.active > a i,.sidebar-menu li:hover > a i{color:#009ddc;}
.sidebar-menu .children li:last-child a{border-bottom:none;}
.sidebar-menu  .parent{border-bottom:1px solid #e1e1e1;}
.sidebar-bottom{position:absolute;bottom:0;padding:16px 25px;text-align:center;left:0;right:0;border-top:1px solid #e1e1e1;background:#000;}
.sidebar-bottom a{color:#fff;font-weight:bold;font-size:24px;}
.content-wrapper{float:left;width:100%;-webkit-transition:all 0.3s ease;-moz-transition:all 0.3s ease;transition:all 0.3s ease;}

/*Header*/
.top-header{display:block;}

/* Horizontal menu that slides from the top or bottom */
.top-header{display:block;background:#fff;-webkit-transition:all 0.35s ease 0s;-moz-transition:all 0.35s ease 0s;transition:all 0.35s ease 0s;background:#fff;z-index:999;width:100%;}
.sticky-header{position:fixed;top:0;width:auto;right:0;left:0}
.sticky-header + .content-area{padding-top:60px}
.page-title{font-size:69px;float:left;font-weight:bold;color:#424242;margin:0;line-height:0;letter-spacing:-0.2px;padding-top:46px}
.notifications a{font-size:20px;color:#424242;} 
.header-right{float:right;width:45%;text-align:right;}
.header-inner{padding:15px 0 15px;-webkit-box-shadow:0 3px 15px 0 rgba(0,0,0,0.15);-moz-box-shadow:0 3px 15px 0 rgba(0,0,0,0.15);box-shadow:0 3px 15px 0 rgba(0,0,0,0.15);width:100%;text-align:center;width:100%;}
.header_sidebar_toggle{font-size:16px;color:#424242;float:left;vertical-align:middle;line-height:0.58;padding:0;margin-top:6px;transition:all 0.35s ease 0s;-webkit-transition:all 0.35s ease 0s;}
.header-left{width:45%;float:left;}
.header-right li a{padding-right:30px;font-size:20px;color:#424242;padding-left:0;padding-right:53px;padding-top:0;padding-bottom:0;}
.profile button{box-shadow:none;border:none;-webkit-appearance:none;appearance:none;}
.profile a,.profile button{background:#424242;width:30px;height:30px;color:#fff;-webkit-border-radius:100%;border-radius:100%;display:block;}
.profile button{background:#424242;width:30px;height:30px;color:#fff;-webkit-border-radius:100%;border-radius:100%;display:block}
.header-right .profile a,.header-right .profile button{color:#fff;padding:9px 0;vertical-align:middle;font-size:16px;font-weight:bold;text-transform:uppercase;line-height:18px;}
.header-right > .profile  >a:focus, .header-right > .profile  >a:hover,.header-right > .profile  >button:focus, .header-right > .profile  >button:hover{background-color:#424242;}
.header-right .notifications a{font-size:31px;padding-right:83px;}
.header-right li a i{line-height:56px;}
.header-right li:last-child a{padding-right:0;}
.header-logo{display:inline-block;vertical-align:middle;width:10%;}
.header-right li{display:inline-block;text-align:center;vertical-align:middle;}
.header-right .profile .dropdown-menu button{color:#000;}
.header-right .profile.open>.dropdown-menu{left:auto;right:0;display:block;padding:5px 15px;}
.login_block .form_wrapper input[type="submit"][disabled],.login_block .form_wrapper input[type="submit"][disabled]:hover,.login_block .form_wrapper input[type="submit"][disabled]:focus{background:#ccc;cursor:not-allowed;border:none;color:#424242;}
.header-right .profile .dropdown-menu button i{vertical-align:middle;padding-right:5px;font-size:22px;line-height:19px;}
.header-right .profile .dropdown-menu button{color:#000;text-transform:capitalize;font-size:19px;width:auto;height:auto;vertical-align:middle;font-weight:normal;padding:15px 0 7px;}
.header-right .profile .dropdown-menu button:hover,.header-right .profile .dropdown-menu button:focus{color:#009ddc;}

/*Footer*/
footer{float:left;background:#fff;-webkit-transition:all 0.35s ease 0s;-moz-transition:all 0.35s ease 0s;transition:all 0.35s ease 0s;position:fixed;bottom:0;z-index:9;left:0;right:0}
.sticky-footer{position:fixed;bottom:0;margin-top:96px;z-index:999}
.footer-inner{text-align:right;border-top:1px solid #bbbbbb;padding:17px 0;font-size:20px}
.footer-inner a{color:#bbbbbb;}

/*Side Menu Responsive*/
@media(max-width:1440px){
body.menu-open main#root{margin-left:300px}
body.menu-open footer#stickyFooter,body.menu-open header.sticky-header{left:300px}
.sidebar{width:300px}
/*.sidebar-top{padding-left:20px;padding-right:20px}
.sidebar-toggle{left:20px}
.sidebar .sidebar-menu .sb-content > ul{padding-left:30px}
.sidebar .sidebar-menu .sb-content ul.children{padding-left:30px}
.sidebar-menu li a, .sidebar-menu .parent>span{padding:22px 15px}
.sidebar-menu .children li a{padding:16px 20px}*/
}

/*Notification sidebar*/
.cbp-spmenu-vertical{height:100%;top:0;z-index:1000;}
.cbp-spmenu-right{right:-9999px;}
.cbp-spmenu-right.cbp-spmenu-open{right:0px;}
.notification-sidebar{width:100%;position:fixed;background:rgba(0,0,0,0.25);}
.notification-sidebar .cbp-spmenu-open:before{display:none;}
.sidebar-title h2{font-weight:bold;color:#000;text-transform:capitalize;font-size:44px;line-height:0;}
.notification-sidebar .sidebar-top{text-align:left;display:flex;width:100%;justify-content:space-between;border-bottom:1px solid #54575a;padding:40px 40px 15px 40px;}
.notify-title-des{font-size:19px;letter-spacing:0.8px;margin-top:20px;color:#009ddc;line-height:1;margin-bottom:0;}
.notify-title-des, .notify-title-des a{color:#009ddc;}
.sidebar-right-toggle{float:right;}
.sidebar-inner{width:440px;background:#fff;height:100%;right:-999px;-webkit-transition:all 0.3s ease;-moz-transition:all 0.3s ease;transition:all 0.3s ease;}
.cbp-spmenu-right.cbp-spmenu-open .sidebar-inner{position:fixed;right:0;}
.sidebar-right-toggle .la-bell-o{font-size:27px;color:#424242;vertical-align:middle;}
.sidebar-right-toggle .la-angle-right{font-size:18px;vertical-align:middle;}
.notification-sidebar .sidebar-right-toggle:focus,.notification-sidebar  .sidebar-right-toggle:hover{color:#424242;}
.notification-sidebar .sidebar-menu{font-family:'TiemposText';padding:0;margin:0;}
.notification-sidebar .sidebar-menu a{color:#000;padding-bottom:0;border:none;padding:0;font-size:19px;border:none;line-height:23px;margin-bottom:2px;}
.notification-sidebar .sidebar-menu ul li:first-child{padding-left:0;}
.notification-sidebar  .sidebar-menu > li{padding-left:40px;border-left:4px solid transparent;}
.notification-sidebar .sidebar-menu ul{padding:0;text-align:left;}
.notification-sidebar .sidebar-menu ul li{display:inline-block;border-right:1px solid #bbbbbb;padding:0 6px;color:#bbbbbb;}
.notification-sidebar  .sidebar-menu > li.active a .notification-sidebar  .sidebar-menu > li:hover  a{border-right:none;}
.notification-sidebar  .sidebar-menu > li.active ,.notification-sidebar  .sidebar-menu > li:hover{border-left:4px solid #009ddc;}
.notification-sidebar  .sidebar-menu li.active > a,.notification-sidebar   .sidebar-menu li:hover > a{border-right:none;}
.notification-sidebar .sidebar-menu ul li:last-child{border-right:none;}
.text-danger{color:#b90000 !important;}
.notification-sidebar .sidebar-top  .notify-title-des a:focus, .notification-sidebar  .sidebar-top .notify-title-des a:hover{color:#009ddc;}
.notification-sidebar .sidebar-menu ul li:first-child{padding-left:0;}
.notify-content{padding:22px 40px 22px 0;border-bottom:1px solid #bbb;}

/*Tabs*/
.nav-tabs{border-bottom:none;padding:0}
.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover{border-width:0;}
.nav-tabs > li > a{border:none;color:#424242;font-size:17px;line-height:22px;letter-spacing:0.8px;text-transform:uppercase;margin:0;padding:0 0 5px}
.nav-tabs > li.active > a, .nav-tabs > li > a:hover{border:none;color:#424242 !important;background:transparent;font-weight:bold;}
.nav-tabs > li > a::after{content:"";background:#009ddc;height:2px;position:absolute;/* width:100%;*/ right:0;left:0;bottom:0;transition:all 250ms ease 0s;transform:scale(0);}
.nav-tabs > li.active > a::after, .nav-tabs > li:hover > a::after{transform:scale(1);}
.tab-nav > li > a::after{background:#21527d none repeat scroll 0% 0%;color:#fff;}
.tab-pane{padding:15px 0;}
.tab-content{padding:15px 0;}
.card{background:#FFF none repeat scroll 0% 0%;box-shadow:0px 1px 3px rgba(0, 0, 0, 0.3);margin-bottom:30px;}
.content-area{margin-top:32px;padding-bottom:80px;height:-webkit-calc(100% - 212px);height:-moz-calc(100% - 212px);height:calc(100% - 212px);min-height:75vh;position:relative;}
.tab-content .action-row + .text-center{position:absolute;top:62%;left:50%;-webkit-transform:translateX(-50%);transform:translate(-50%, -50%);}
.nav-tabs>li{margin-right:50px}
.nav-tabs>li:last-child{margin-right:0}

/*User page*/
.action-row{border-top:1px solid #54575a;border-bottom:1px solid #54575a;padding:10px 10px;}
.action-row a{margin-right:35px;}
.action-row button{margin-right:15px}
.action-row a i{padding-right:3px;}
.btn-rounded i{padding:0 !important;line-height:28px !important;width:30px;}
.action-row .btn-rounded{margin-right:15px;color:#fff;}
.action-row .btn-rounded i.la-plus{padding-right:1px !important}
.action-row .search-btn{float:right;margin:0;line-height:1;font-size:16px;-webkit-appearance:none;border:none;background:none;box-shadow:none;position:absolute;right:0;top:50%;transform:translateY(-50%);} 
.search-form{float:right;position:relative;margin:10px 0;}
.action-row .search-form{margin:0 0;}
/*.search-form:hover input,*/.search-form.active input{min-width:300px; box-shadow:none;border-radius:0px;border:none;border-bottom:1px solid #424242;}
.search-form input{width:0px;padding:10px 22px 10px 0;border:none;transition:all 0.3s ease;box-shadow:none; }
.search-form input:focus{border-color:#424242;}
.user-table .table>tbody>tr>td:nth-child(3),.user-table .table>tbody>tr>td:nth-child(4){font-weight:bold;color:#000;font-size:16px;}
.user-table .table>tbody>tr>td:nth-child(5),.user-table .table>tbody>tr>td:nth-child(6){color:#b1b4b3;font-size:15px;font-family:'TiemposText';}
.user-table .table>tbody>tr>td:nth-child(7){color:#000;font-family:'TiemposText';font-size:16px;}
.user-table .table>tbody>tr>td.actions{color:#424242;font-size:16px;}
.user-table .table>tbody>tr>td:nth-child(1), .user-table .table>thead>tr>th:nth-child(1){width:4%;max-width:4%;}
.user-table .table>tbody>tr>td:nth-child(2), .user-table .table>thead>tr>th:nth-child(2){width:3%;padding-right:5px;padding-left:10px;max-width:3%;}
.user-table .table>tbody>tr>td:nth-child(3), .user-table .table>thead>tr>th:nth-child(3){width:16%;max-width:150px;}
.user-table .table>tbody>tr>td:nth-child(4), .user-table .table>thead>tr>th:nth-child(4){width:18%;max-width:150px;}
.user-table .table>tbody>tr>td:nth-child(5), .user-table .table>thead>tr>th:nth-child(5){width:27%;max-width:150px;}
.user-table .table>tbody>tr>td:nth-child(6), .user-table .table>thead>tr>th:nth-child(6){width:19%;max-width:19%;}
.user-table .table>tbody>tr>td:nth-child(7), .user-table .table>thead>tr>th:nth-child(7){width:7%;max-width:7%;}
.user-table .table>tbody>tr>td.actions, .user-table .table>thead>tr>th.actions{width:6%;text-align:right;max-width:6%;}
.actions .btn-default{opacity:1;border:none;box-shadow:none;background-color:transparent !important;font-size:26px;color:#424242;   padding-right:0;}
.actions  .dropdown-menu>li>a:focus, .dropdown-menu>li>a:hover{color:#B90000; background-color:transparent;}
.actions .dropdown-menu>li>a{padding:12px 26px;font-size:19px;line-height:1;color:#000;}
.actions .dropdown-menu{font-size:16px;right:0;left:auto;border-radius:0px;-webkit-box-shadow:0 4px 12px 0 rgba(0,0,0,.20);box-shadow:0 4px 12px 0 rgba(0,0,0,.20);border:none;min-width:190px;}
.actions .dropdown-menu>li> .delete-action{color:#b90000;}
/*.user-table .table>tbody>tr:nth-child(even) .profile-pic span{background:#424242;}
.user-table .table>tbody>tr:nth-child(odd) .profile-pic span{background:#bbbbbb;}*/
.user-table .table>tbody>tr:last-child .actions .dropdown-menu,.user-table .table>tbody>tr:nth-last-child(2) .actions .dropdown-menu{top:auto;bottom:0;}
.modal-para{font-size:19px;margin-bottom:30px;margin-top:0; /*font-family:'TiemposText';*/color:#000000;}
.modal-para p{margin-bottom:20px;}
.modal-footer .btn-delete{background:#b90000;}
.modal .btn-delete:hover, .modal .btn-delete:focus.modal .btn-delete:active:focus, .modal .btn-delete:active:hover{background:#fff;border:1.5px solid #b90000;color:#b90000;}

/*Add user*/
.sub-title{font-size:28px;line-height:1.2;font-weight:bold;color:#424242;margin:0;letter-spacing:-0.2px;text-align:center;margin-top:23px;margin-bottom:25px}
.btn-left{float:left}
.btn-right{float:right}
.btn-right .btn:last-child{margin-right:0;}
.add-action-row{padding:10px 10px;}
.add-user-container{width:1048px;margin:75px auto 75px;max-width:100%;}
.form-title{margin-top:0;margin-bottom:25px;font-size:26px;font-weight:bold;color:#000000;}
.plus-btn{position:relative;padding-left:77px;font-weight:bold;font-size:27px;color:#bbb;line-height:1;    margin-top:15px;}
.plus-btn:before{content:"\f2c2";font-family:LineAwesome;width:62px;height:62px;border-radius:100%;background:#bbb;font-size:37px;color:#fff;position:absolute;top:0;line-height:62px;left:0;text-align:center;bottom:0;margin:-18px auto 0;font-weight:normal;}
.step-list{padding:0;margin:30px 0;font-size:16px;color:#000000;}
.step-list li p{line-height:1.3;margin:0;}
.step-list li .step-info{font-size:14px}
.step-info{font-family:'TiemposText';}
.step-list li{margin-bottom:20px;}
.importuser_modal .modal-footer{margin-top:85px;} 

/*Content*/
.content-table .table>tbody>tr>td:nth-child(3){font-weight:bold;color:#000;font-size:16px;}
.content-table .table>tbody>tr>td:nth-child(4),.content-table .table>tbody>tr>td:nth-child(5),.content-table .table>tbody>tr>td:nth-child(6){color:#b1b4b3;font-size:14px;font-family:'TiemposText';}
.content-table .table>tbody>tr>td:nth-child(7){color:#000;font-family:'TiemposText';font-size:14px;}
.content-table .table>tbody>tr>td.actions{color:#424242;font-size:16px;}
.content-table .table>tbody>tr>td:nth-child(1),.content-table .table>thead>tr>th:nth-child(1){width:5%;max-width:5%;}
.content-table .table>tbody>tr>td:nth-child(2),.content-table .table>thead>tr>th:nth-child(2){width:5.5%;padding-right:5px;padding-left:5px;max-width:5.5%;}
.content-table .table>tbody>tr>td:nth-child(3),.content-table .table>thead>tr>th:nth-child(3){width:33%;max-width:33%;}
.content-table .table>tbody>tr>td:nth-child(4),.content-table .table>thead>tr>th:nth-child(4){width:21.5%;max-width:21.5%;}
.content-table .table>tbody>tr>td:nth-child(5),.content-table .table>thead>tr>th:nth-child(5){width:10%;max-width:10%;}
.content-table .table>tbody>tr>td:nth-child(6),.content-table .table>thead>tr>th:nth-child(6){width:10%;max-width:10%;}
.content-table .table>tbody>tr>td:nth-child(7),.content-table .table>thead>tr>th:nth-child(7){width:10%;max-width:10%;}
.content-table .table>tbody>tr>td.actions,.content-table .table>thead>tr>th.actions{width:5%;text-align:right;max-width:5%;}
.modal-para-small{font-size:13px;margin-bottom:20px;font-family:'TiemposText';margin-top:0;}
.modal-para-small p{margin-bottom:0;}
.upload-img img{width:100%;}
.addcontent_modal .modal-footer{margin-top:20px;}
.file-progress{padding:0;color:#bbb;font-family:'TiemposText';font-size:15px}
.upload-list > li{border-bottom:1px solid #bbb;padding:10px 0;position:relative;}
.upload-detail.selected-file-list li{position:relative}
.upload-detail.selected-file-list li:before,.upload-detail.selected-file-list li:after{content:'';clear:both;display:table}
.upload-detail.selected-file-list .file-progress{font-size:14px}
.upload-detail.selected-file-list .file-progress .text-info{color:#009ddc}
.upload-detail.selected-file-list .file-info{max-width:-webkit-calc(100% - 70px);max-width:-moz-calc(100% - 70px);max-width:calc(100% - 70px)}
.remove-file{color:#bbbbbb;display:inline-block;vertical-align:middle;font-size:18px;padding-right:10px;padding-left:8px;cursor:pointer}
.remove-file:hover{color:#424242}
.file-name{color:#000000;margin-bottom:0;font-size:15px;line-height:1.1;}
.file-info{display:inline-block;vertical-align:middle;font-size:15px;max-width:-webkit-calc(100% - 40px);max-width:-moz-calc(100% -40px);max-width:calc(100% - 40px);}
.file-progress li{display:inline-block;padding-right:8px;border-right:1px solid #bbb;line-height:1.1;margin-right:8px}
.file-progress li:last-child{border-right:none;margin-right:0;}
.file-progress li i{vertical-align:middle;}
.upload-detail{margin-top:0;max-height:250px;}
.upload-detail ul{padding:0;}
.remove-file i{vertical-align:middle;}
.btn-retry{text-decoration:underline;}
.modal-footer-inner{border-top:1px solid #54575a;margin-top:70px;padding-top:30px;margin-bottom:0}
.modal-footer-inner.clearfix{margin-bottom:0}
.search-form-modal{width:100%;}
.search-form-modal:hover input{width:100%;}
.upload-img{font-size:16px;border:2px dashed #bbb;text-align:center;padding:40px 0 50px;color:#000000;font-family:'TiemposText';}
.upload-img i{color:#bbb;font-size:28px;}
.upload-img span{font-size:13px;margin-bottom:20px;display:block;}
.upload-img .black-btn{font-size:12px;line-height:16px;font-family:'Calibre';letter-spacing:0.8px;padding:10px 25px 6px;-webkit-border-radius:4px;border-radius:4px;margin-bottom:0}
.upload-img p{line-height:1;margin-bottom:20px;}

/*Content Details*/
.title-grey{color:#424242;}
.preview-row{margin-top:70px;margin-bottom:70px;display:flex;flex-wrap:wrap;align-items:stretch;}
.search-left{float:right;} 
.search-left:hover{width:100%;}
.search-left input{padding:10px 12px 10px 0px;}
.search-left:hover input{padding:10px 12px 10px 0px;font-size:16px;color:#424242;}
.search-left:hover input{width:100%;}
.search-left .search-btn{right:0;left:auto;}
.search-form.search-left.visible_field input{min-width:100%;max-width:100%;}
.search-form.search-left.visible_field{width:100%;}
.file-img{width:30px;display:inline-block;vertical-align:middle;}
.pages-list .custom-checkbox{width:100%;}
.pages-list ul{padding:0;}
.pages-list li{padding-left:25px;border-bottom:1px solid #e1e1e1;padding:7px 0 7px 10px;}
.pages-list .custom-checkbox label{width:100%;height:auto;padding-left:40px;}
.custom-checkbox [type="checkbox"] + label:before{left:0;}
.page-info{display:inline-block;padding-left:13px;vertical-align:middle;padding-top:5px;max-width:-webkit-calc(100% - 40px);max-width:-moz-calc(100% - 40px);max-width:calc(100% - 40px);}
.page-name{font-size:16px;display:block;font-weight:bold;line-height:1;color:#000000;}
.page-text{color:#bbb;font-size:14px;font-weight:normal;display:block;line-height:1;font-family:'TiemposText';}
.pages-list{max-height:300px;}
.preview-box-doc{background:#f1f1f1;/*background:-moz-linear-gradient(top, #bbbbbb 0%, #ffffff 100%);background:-webkit-linear-gradient(top, #bbbbbb 0%,#ffffff 100%);background:linear-gradient(to bottom, #bbbbbb 0%,#ffffff 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#bbbbbb', endColorstr='#ffffff',GradientType=0 );*/ width:100%;display:flex;align-items:center;justify-content:center;}
.preview-box-doc iframe{width:100%;height:100%;border:none}
.preview-box{background:#F1F1F1;width:100%;display:block;align-self:flex-start;padding:50px 0px;text-align:center;}
.preview-box img{margin:0 auto;}
.preview-box .white-btn{font-size:40px;font-weight:bold;}
.preview-box-audio{width:100%;display:flex;}
.preview-row > div:first-child{display:flex;justify-content:center;}
.preview-error-box{background:#f1f1f1;}
.preview-error{color:#bbb;text-align:center;}
.preview-error i{font-size:50px;}
.preview-error p{font-family:'TiemposText';font-size:28px;color:#000;}
.table>tbody>tr>td .custom-checkbox [type="checkbox"] + label:before, .user-table.pages-table .table>thead>tr>th .custom-checkbox [type="checkbox"] + label:before{left:15px;}

/*pages*/
.user-table.pages-table .table>tbody>tr>td:nth-child(1),.user-table.pages-table .table>thead>tr>th:nth-child(1){width:4%;max-width:4%;}
.user-table.pages-table .table>tbody>tr>td:nth-child(2),.user-table.pages-table .table>thead>tr>th:nth-child(2){width:5%;padding-right:5px;padding-left:5px;max-width:5%;}
.user-table.pages-table .table>tbody>tr>td:nth-child(3),.user-table.pages-table .table>thead>tr>th:nth-child(3){width:35%;max-width:35%;}
.user-table.pages-table .table>tbody>tr>td:nth-child(4),.user-table.pages-table .table>thead>tr>th:nth-child(4){width:18%;max-width:18%;}
.user-table.pages-table .table>tbody>tr>td:nth-child(5),.user-table.pages-table .table>thead>tr>th:nth-child(5){width:12%;max-width:12%;}
.user-table.pages-table .table>tbody>tr>td:nth-child(6),.user-table.pages-table .table>thead>tr>th:nth-child(6){width:9%;max-width:9%;}
.user-table.pages-table .table>tbody>tr>td:nth-child(4){color:#b1b4b3;font-size:16px;font-family:'TiemposText';font-weight:400;}
.user-table.pages-table .table>tbody>tr>td:nth-child(3) p{color:#b1b4b3;font-size:14px;font-family:'TiemposText';font-weight:400;line-height:22px;margin-bottom:0;}
.pageeditor_block{padding-top:50px;}
.pageeditor_block textarea{display:block;width:100%;border:1px solid #bbb;border-radius:10px;resize:none;font-size:16px;color:#000;font-family:'TiemposText';padding:20px}
.add_page_list{padding-left:0px;}
.add_page_list li{margin-bottom:20px;}
.add_page_list li a,.add_page_list li button{font-size:18px;text-transform:uppercase;font-family:'Calibre';color:#bbbbbb;-webkit-appreance:none;apperance:none;background:none;box-shadow:none;border:none;padding:0;}
.add_page_list li a:hover,.add_page_list li button:hover{color:#009ddc;}
.img_upload_block p{font-family:'TiemposText';font-size:16px;color:#000;}
.show_upload_img_block{border:1px solid #424242;border-radius:3px;width:100px;height:103px;float:left;}
.show_upload_img_block img{max-height:100%;max-width:100%}
.upload_img_aria{float:left;width:calc(100% - 100px);padding-left:25px;}
.upload_img_btn{margin-top:15px;padding:10px 15px 6px;font-size:12px;line-height:16px;border-radius:4px;}
.upload_img_btn i{margin-right:5px}
.noactive_data{color:#b90000;}
.img_set_block{margin:0 auto;text-align:center;margin-top:25px;}
.img_set_block a,.img_set_block button{display:inline-block;width:auto;font-size:14px;line-height:18px;padding:10px 25px 5px}
.img_set_block a i,.img_set_block button i{margin-right:5px}
.pageeditor_block .upload-detail{margin-bottom:200px}
.pageeditor_block .pages-list ul li.page-content-item img{display:inline-block;max-width:30px}

/************bredcrum_block******************/
.bredcrum_block{background-color:#f1f1f1;margin-top:25px;}
.bredcrum_block .bredcrum ul{padding:10px 12px;margin-bottom:0px;}
.bredcrum_block .bredcrum ul li{float:left;position:relative}
.bredcrum_block .bredcrum ul li:after{content:"/";position:absolute;top:4px;color:#b1b4b3;}
.bredcrum_block .bredcrum ul li.active:after{content:"";}
.bredcrum_block .bredcrum ul li a{font-size:20px;margin-right:15px;margin-left:15px;color:#b1b4b3;}
.bredcrum_block .bredcrum ul li a:hover{color:#000;}
.bredcrum_block .bredcrum ul li.active a{color:#000;}
.bredcrum_block .bredcrum ul li:first-child a{margin-left:0px;}

/*************Loginpage************/
.card_block{border-radius:10px;background-color:#fff;box-shadow:1px 2px 11px 1px #000000a8;margin-bottom:30px;padding:15px;}
.card_block input{border:none;border-bottom:1px solid #959595;border-radius:0px;font-size:26px;}
.login_block{max-width:600px;margin:0 auto;margin-bottom:100px;}
.login_block .form_wrapper{background:#fff;max-width:100%;box-sizing:border-box;padding:25px;margin:2% auto 0;position:relative;z-index:1;}
.login_block .form_wrapper .title_container{text-align:center;padding-bottom:15px;}
.login_block .form_wrapper .row{margin:10px -15px;}
.login_block .form_wrapper .row > div{padding:0 15px;box-sizing:border-box;}
.login_block .form_wrapper .col_half{width:100%;float:none;}
.login_block .form_wrapper .col_half.full-width{width:100%;float:none;}
.login_block .form_wrapper .input_field{position:relative;margin-bottom:20px;}
.login_block .form_wrapper .input_field > span:not(.has-error){position:absolute;left:0;top:0;color:#333;height:34px;border-right:1px solid #ccc;text-align:center;width:30px;}
.login_block .form_wrapper .input_field > span > i{padding-top:10px;}
.login_block .form_wrapper .textarea_field > span > i{padding-top:10px;}
.login_block .form_wrapper input[type="text"]{background-color:none !important;width:100%;padding:8px 10px 9px 35px;height:35px;box-sizing:border-box;outline:none;-webkit-transition:all 0.30s ease-in-out;-moz-transition:all 0.30s ease-in-out;-ms-transition:all 0.30s ease-in-out;transition:all 0.30s ease-in-out;}
.login_block .form_wrapper input[type="text"]:focus{background-color:transparent !important;}
.login_block .form_wrapper input[type="email"]{background-color:transparent !important;width:100%;padding:8px 10px 9px 35px;height:35px;box-sizing:border-box;outline:none;-webkit-transition:all 0.30s ease-in-out;-moz-transition:all 0.30s ease-in-out;-ms-transition:all 0.30s ease-in-out;transition:all 0.30s ease-in-out;}
.login_block .form_wrapper input[type="email"]:focus{background-color:transparent !important;}
.login_block .form_wrapper input[type="date"]{background-color:transparent !important;width:100%;padding:8px 10px 9px 35px;height:35px;box-sizing:border-box;outline:none;-webkit-transition:all 0.30s ease-in-out;-moz-transition:all 0.30s ease-in-out;-ms-transition:all 0.30s ease-in-out;transition:all 0.30s ease-in-out;}
.login_block .form_wrapper input[type="password"]{background-color:transparent !important;width:100%;padding:8px 10px 9px 35px;height:35px;box-sizing:border-box;outline:none;-webkit-transition:all 0.30s ease-in-out;-moz-transition:all 0.30s ease-in-out;-ms-transition:all 0.30s ease-in-out;transition:all 0.30s ease-in-out;}
.login_block .form_wrapper input[type="password"]:focus{background-color:transparent !important;}
.login_block .form_wrapper input[type="submit"]{color:#fff;background:#424242;border-color:#424242;border-radius:9px;text-transform:uppercase;font-size:19px;padding:20px 20px 11px 20px;line-height:22px;box-sizing:border-box;vertical-align:middle;width:250px;margin:0 auto;text-align:center;display:block;border:1px solid;}
.login_block .form_wrapper input[type="submit"]:hover{color:#424242;background:#fff;border-color:#424242;}
.login_block .form_wrapper input[type="submit"]:focus{color:#424242;background:#fff;border-color:#424242;}
.card_block.login_block input{font-size:15px;}

/************pagedetail-New******************/
.detail-new-block{margin:0 auto;display:block;float:none;}
.detail-new-block .page_thumb img{margin:0 auto;}
.detail-new-block .page_title{font-weight:bold;color:#000;font-size:26px;margin-bottom:5px;line-height:28px;margin-top:30px}
.detail-new-block .page_info{text-align:center;}
.detail-new-block .sub-title{font-family:'TiemposText';font-size:16px;line-height:20px;color:#000;padding-bottom:17px;margin-top:0;border-bottom:1px solid #54575a;margin-bottom:20px;text-transform:capitalize;}
.detail-new-block .page_info p{font-size:16px;font-family:'TiemposText';font-weight:400;line-height:24px;text-align:left;color:#000;}
.detail-new-block .hastag_list li{display:inline-block;color:#b1b4b3;font-size:16px;font-family:'TiemposText';font-weight:400;line-height:24px;padding:0 5px;}
.detail-new-block .hastag_list{padding:0;text-align:left;margin-top:15px;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;margin-left:-5px;margin-right:-5px}
.detail-new-block .edit_page_block .page_title{float:left;margin:10px 0 0;font-size:24px;line-height:26px}
.detail-new-block  .edit_page_block .search-form-modal{float:right;width:auto;margin-top:0;margin-bottom:0;}
/*.detail-new-block .edit_page_block .search-form-modal:hover{width:-webkit-calc(100% - 200px);width:-moz-calc(100% - 200px);width:calc(100% - 200px);}*/
.detail-new-block  .edit_page_block{margin-top:35px;margin-bottom:50px;}
.detail-new-block  .edit_page_block .action-row{border-top:none;padding-left:0;padding-bottom:13px;padding-right:0}
.detail-new-block .menu_icon{float:right;margin-right:15px;line-height:70px;font-size:30px;color:#bbb;}
.detail-new-block .menu_icon i{vertical-align:middle;}
.detail-new-block  .edit_page_block .pages-list li label{max-width:100%;width:100%;margin-bottom:0;}
.detail-new-block  .edit_page_block .page-info{max-width:-webkit-calc(100% - 30px);max-width:-moz-calc(100% - 30px);max-width:calc(100% - 30px);}
.detail-new-block  .edit_page_block  .pages-list{max-height:350px;}
.detail-new-block  .search-form input{box-shadow:none;}
.detail-new-block .pages-list li{clear:both;padding:10px 0 10px 10px;border-bottom:1px solid #e1e1e1;cursor:pointer;}
.detail-new-block .pages-list li:hover{background-color:#F1F1F1 !important;}
.detail-new-block .action-row .search-btn{font-size:20px;}
.detail-new-block .edit_page_block .pages-list .page-content-item a{display:block}
.detail-new-block .edit_page_block .pages-list .page-content-item img{display:inline-block;max-width:30px;}
.pagination>li>a, .pagination>li>span{position:relative;float:left;padding:12px 20px 7px;margin-left:-1px;line-height:1;color:#000000;text-decoration:none;background-color:#fff;border:1px solid #ddd;font-size:18px;}
.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover{z-index:3;color:#fff;cursor:hover;background-color:#000000;border-color:#000;}
.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover{z-index:2;color:#fff;background-color:#000;border-color:#000}
.pagination:after{content:'';display:block;clear:both;}
.pagination{width:auto;display:block;margin:0 auto;text-align:center;max-width:100%;}
.pagination>li{display:inline-block;}
.has-error{border:none;}
.add-more-btn{border-radius:7px;padding:15px 40px 9px;letter-spacing:1px;position:relative;background:none;border:none;color:#bbb;text-transform:initial;font-size:16px;padding-left:0;}
.add-more-btn:before{content:"\F2C2";font:normal normal normal 16px/1 LineAwesome;width:30px;height:30px;border-radius:50%;font-size:18px;line-height:30px;padding-left:0;text-align:center;padding:0;text-align:center;background-color:#bbbbbb;border-color:#bbbbbb;letter-spacing:0.8px;color:#fff;display:inline-block;vertical-align:middle;margin-right:15px;text-align:center;}
body .img_set_block + .upload-detail .upload-list{overflow-x:hidden !important;overflow-y:auto !important;max-height:none;margin-bottom:60px;height:auto !important;}
body .img_set_block + .upload-detail{max-height:250px;overflow:auto;}
.pageeditor_block .upload-detail{margin-bottom:0;}
.addcontent_modal .upload-list{max-height:250px;height:auto !important;margin:20px 0 0}
.upload-list.upload-list-page{overflow-y:hidden !important;height:auto !important;margin-bottom:100px;}
.upload-list.upload-list-csv{overflow-y:hidden !important;height:85px !important;padding-left:0px !important;}
.content-action-row .search-form.active input{min-width:220px;}
/* .change-setting-modal .modal-title{font-size:39px;} */
.bredcrum_block .bredcrum ul{padding:8px 12px 0px;}
.upload-list > li > span{font-size:20px;color:#bbbbbb;font-weight:normal;line-height:40px;padding-right:10px;}
.img_set_block .file-info{max-width:-webkit-calc(100% - 100px);max-width:-moz-calc(100% - 100px);max-width:calc(100% - 100px);}
.styles_overlay__CLSq-{background:rgba(0, 0, 0, 0.5);}
.addcontent_modal  .pagination{margin-top:25px;}
.addcontent_modal .assigned-pages .action-row{padding:7px 10px;margin-bottom:0;}
.addcontent_modal .assigned-pages .action-row .custom-checkbox [type="checkbox"] + label:before{top:4px;}
.addcontent_modal .assigned-pages  .action-row .search-form{margin:0 0;}
/* .addcontent_modal .assigned-pages .custom-checkbox [type="checkbox"] + label:before{width:31px;height:31px;line-height:31px;}
.addcontent_modal .assigned-pages .custom-checkbox [type="checkbox"]{width:31px;height:31px;} */
.addcontent_modal .assigned-pages .bredcrum_block .bredcrum ul{padding:8px 17px 0px;}
.assigned-pages .add-pages-list ul li{padding:10px 0}
.table > tbody > tr > td.custom-checkbox [type="checkbox"] + label:before, .table > thead > tr > th.custom-checkbox [type="checkbox"] + label:before{left:15px;}
.action_btn_group{display:inline-block;width:-webkit-calc(100% - 45px);width:-moz-calc(100% - 45px);width:calc(100% - 45px);vertical-align:middle;}
.add_page_modal .modal-footer{margin-top:0;}
/* .add_page_modal .assigned-pages .action-row .search-form:hover,.add_page_modal .assigned-pages .action-row .search-form.active{width:70%;} */
.user-table.pages-table .table > tbody > tr > td:nth-child(3), .user-table.pages-table .table > thead > tr > th:nth-child(3){width:32%;max-width:32%;}
.user-table.pages-table .table > tbody > tr > td:nth-child(4), .user-table.pages-table .table > thead > tr > th:nth-child(4){width:21%;max-width:21%;}
.page_thumb{max-width:200px;margin:0 auto;}
body .has-error{position:absolute;border:none;margin:0;padding:0;font-size:15px;line-height:25px;}
.login_block .has-error{position:absolute;border:none;margin:3px 0 0 0;padding:0;font-size:15px;line-height:15px;height:auto;}
.login_block .form_wrapper .input_field > *:not(.has-error){display:table-cell;vertical-align:middle;height:34px !important;line-height:35px;}
.login_block .form_wrapper .input_field > input{padding:10px 10px 5px 35px;}
.login_block .form_wrapper .input_field > input::-webkit-input-placeholder{transform:translateY(0.5px);}
.login_block .form_wrapper .input_field > input::-moz-placeholder{transform:translateY(0.5px);}
.login_block .form_wrapper .input_field > input:-ms-input-placeholder{transform:translateY(0.5px);}
.login_block .form_wrapper .input_field > input:-moz-placeholder{transform:translateY(0.5px);}
.login_block .form_wrapper .input_field{display:table;width:100%;}
.user-table.pages-table .table>tbody>tr>td.content-icon a{display:block;}
.user-table.pages-table .table>tbody>tr>td.content-icon a img{border:1px solid #b5b6b7;border-radius:9px;width:100%;}
.custom-checkbox{cursor:pointer;}
.table-container .table > thead th .th-name{cursor:pointer;}
th.custom-checkbox [type="checkbox"] + label:before{cursor:default;}
.custom_body{/*max-height:57vh;*/overflow:auto;}
.search-form.visible_field input{width:100%;min-width:100% !important;border-bottom:1px solid #000;border-radius:0;font-size:18px;color:#000;max-width:250px;float:right;}
.search-form.visible_field .fa-search:before{content:'\f00d';}
.pagination>li>a{height:50px;line-height:30px;}
.pagination li.first a, .pagination li.prev a, .pagination li.next a, .pagination li.last a{font-size:0;}
.pagination li a:before{font-size:18px;font-family:FontAwesome;line-height:25px;}
.pagination .first a:before{content:'\f100';}
.pagination  .prev a:before{content:'\f104';}
.pagination li.next a:before{content:'\f105';}
.pagination li.last a:before{content:'\f101';}
.parent.no-collaps a.active{color:#337ab7;}

/*custom scrollbar*/
.sb-container{position:relative;overflow:hidden;padding-right:10px;height:100%;}
.sb-content{height:auto;width:-moz-calc(100% + 30px);width:-webkit-calc(100% + 30px);width:-o-calc(100% + 30px);width:calc(100% + 30px);padding-right:0;overflow-y:scroll;box-sizing:border-box;padding-bottom:10%;}
.sb-scrollbar-container{position:absolute;right:5px;bottom:5px;top:5px;width:5px;}
.sb-container-noscroll .sb-scrollbar-container{right:-20px;}
.sb-scrollbar{position:absolute;right:0;height:30px;width:5px;border-radius:10px;background:#009ddc;}
.sb-scrollbar:hover{background:rgba(0, 0, 0, 0.5);}
.sidebar-menu .children li a.active{color:#009ddc;}
.sidebar-menu .parent span.open_menu:after{transform:rotate(-90deg);}
.sidebar-menu .parent.no-collaps span:after{display:none;}
/*.pagination>li:nth-child(2),.pagination>li:nth-last-child(2){position:relative;}
.pagination>li:nth-child(2) a,.pagination>li:nth-last-child(2) a{text-indent:-999999px;cursor:pointer;}
.pagination>li:nth-child(2):before{position:absolute;content:'\F104';font-family:'FontAwesome';color:#777;display:inline-block;font-size:18px;top:10px;left:0;display:block;z-index:1;text-align:center;width:41px;}
.pagination>li:nth-last-child(2):before{position:absolute;content:'\f105';font-family:'FontAwesome';color:#777;display:inline-block;font-size:18px;top:10px;left:0;display:block;z-index:1;text-align:center;width:41px;}
.pagination>li:nth-last-child(2):hover:before,.pagination>li:nth-child(2):hover:before{color:#fff;background:#000;}*/
/*SideMenu*/

/*Edit Content page Media Query*/
@media screen and (max-width:1600px){
/*.action_btn_group{vertical-align:middle;width:-webkit-calc(100% - 60px);width:-moz-calc(100% - 60px);width:calc(100% - 60px);}
.action-row .btn-default{font-size:15px;margin-right:15px;padding:8px 21px 6px;}
.action-row button{margin-right:15px;}
.action-row .btn-rounded{margin-right:15px;}*/
}

@media screen and (max-width:1440px){
.content-action-row .search-form{margin:6px 0;/*width:17%;*/}
.action-row button{margin-right:12px;}
.action_btn_group{vertical-align:middle;}
/* .sidebar-menu{max-height:-webkit-calc(100vh - 195px);max-height:-moz-calc(100vh - 195px);max-height:calc(100vh - 195px);} */
.modal-para{font-size:17px;}
.upload-img p{margin-bottom:10px;}
.page-text{font-size:13px;}
.step-list{margin:20px 0;font-size:17px;}
.sub-title{font-size:36px;}
.modal-para p{margin-bottom:15px;}
.page-title{font-size:55px;line-height:51px;padding-top:11px;height:55px;}
.content-action-row .search-form.active input{min-width:192px;}
.content-table .table>tbody>tr>td:nth-child(1),.content-table .table>thead>tr>th:nth-child(1){width:6%;max-width:6%;}
.content-table .table>tbody>tr>td:nth-child(2),.content-table .table>thead>tr>th:nth-child(2){padding-left:10px;width:5.5%;max-width:5.5%;min-width:45px;}
.content-table .table>tbody>tr>td:nth-child(3),.content-table .table>thead>tr>th:nth-child(3){width:32%;max-width:32%;}

/*.action-row .btn-default{font-size:15px;padding:8px 16px 6px;}
.assigned-pages .form-title{font-size:30px;}
.pages-list .page-name{font-size:23px;}
body.menu-open .action_btn_group{width:75%;}
.user-table .table > tbody > tr > td:nth-child(5), .user-table .table > tbody > tr > td:nth-child(6), .content-table .table > tbody > tr > td:nth-child(4), .content-table .table > tbody > tr > td:nth-child(5), .content-table .table > tbody > tr > td:nth-child(6){font-size:16px;}
.content-table .table > tbody > tr > td:nth-child(3), .user-table .table > tbody > tr > td:nth-child(3), .user-table .table > tbody > tr > td:nth-child(4){font-size:22px;}
.table > tbody > tr > td, .table > tbody > tr > th, .table > tfoot > tr > td, .table > tfoot > tr > th, .table > thead > tr > td, .table > thead > tr > th, .content-table .table > tbody > tr > td:nth-child(7), .user-table.pages-table .table > tbody > tr > td:nth-child(4){font-size:17px;}
.user-table .table > tbody > tr > td:nth-child(7){font-size:16px;}
.user-table.pages-table .table > tbody > tr > td:nth-child(3) p{font-size:15px;}
.actions .dropdown-menu > li > button{font-size:17px;padding:8px 25px;}
.actions .dropdown-menu > li > a.page-action.edit-action{font-size:17px !important;padding:8px 25px;}
.modal-header .modal-title{font-size:32px;}
.upload-img .black-btn{font-size:18px;padding:14px 31px 8px;}
.addcontent_modal .modal-footer{margin-top:65px;}
.file-name{font-size:20px;}
.modal-para-small{font-size:17px;line-height:1.47;}
.modal-para p,.modal-para-small p{margin-bottom:15px;font-size:17px;}
.pages-list .page-name{font-size:18px;}
.upload-img{font-size:20px;padding:20px 0;}
.upload-img i{font-size:42px;}
.upload-img span{font-size:16px;margin-bottom:10px;display:block;}
.modal-footer .btn-default, .modal-footer .btn-primary{font-size:18px;padding:18px 36px 13px;border-radius:6px;line-height:22px;}
.form-container .form-group .form-control{font-size:16px;}
.form-container .form-group label{font-size:16px;}
.header-inner{padding:20px 36px 20px;}
.action-row .btn-rounded{margin-right:15px;color:#fff;width:45px;height:45px;font-size:24px;line-height:45px;}
.action-row  .btn-rounded i{line-height:44px !important;width:45px;}
.header_sidebar_toggle{padding:13px 38px 13px 0;}
.form-title{font-size:30px;}
.content-table .table>tbody>tr>td:nth-child(3),.content-table .table>thead>tr>th:nth-child(3){width:32%;max-width:32%;}
.sidebar-menu li.parent > a, .sidebar-menu .parent > span{font-size:24px;}
.sidebar-menu li a{font-size:16px;}
.sidebar-bottom a{font-size:22px;}*/
}

@media screen and (max-width:1366px){
.detail-new-block .menu_icon{line-height:61px;}
/* .detail-new-block .page-name{font-size:24px;}
.detail-new-block .page-text{font-size:15px;} */
.detail-new-block .edit_page_block .search-form-modal{margin:0;}
.header-logo img{display:block;max-width:100%;}
.assigned-pages .action-row .search-form.visible_field input, .assigned-pages .action-row .search-form.visible_field:hover input, .assigned-pages .action-row .search-form.visible_field .active input{min-width:189px;}
/*.action-row .btn-default{margin:10px 8px 10px 0;}
body.menu-open .action-row .btn-default{padding:8px 10px 6px;}*/
.content-action-row .action-row .btn-default{margin-top:0;}
.content-action-row .search-form{margin:10px 0;}
}

@media screen and (max-width:1280px){
.user-table .table > tbody > tr > td:nth-child(6), .user-table .table > thead > tr > th:nth-child(6){width:21%;max-width:21%;}
.user-table .table > tbody > tr > td:nth-child(5), .user-table .table > thead > tr > th:nth-child(5){width:25%;max-width:25%;}
/*.content-action-row .search-form{width:100%;float:none;overflow:hidden;}
.content-action-row .search-form input{padding:10px 36px 10px 12px;}
.content-action-row .search-form input, .content-action-row .search-form.active input{width:100%;min-width:300px;box-shadow:none;border-radius:0px;border:none;border-bottom:1px solid #424242;}
.action_btn_group{width:92%;}
body.menu-open .action_btn_group{width:89%;}
.search-form.visible_field input{max-width:100%;}*/
}

@media screen and (max-width:1024px){
.detail-new-block{width:100%;}
.user-table .table > tbody > tr > td, .user-table .table > thead > tr > th, .content-table .table > tbody > tr > td, .content-table .table > thead > tr > th, .user-table.pages-table .table > tbody > tr > td:nth-child(3), .user-table.pages-table .table > thead > tr > th:nth-child(3){white-space:nowrap;min-width:40px;width:auto;max-width:none;}
/*.action-row .search-form{width:100%;float:none;overflow:hidden;}
.action-row .search-form input{padding:10px 36px 10px 12px;}
.action-row .search-form input, .action-row .search-form:hover input,.action-row .search-form.active input{width:100%;min-width:300px;box-shadow:none;border-radius:0px;border:none;}
.sidebar-menu{max-height:-webkit-calc(100vh - 200px);max-height:-moz-calc(100vh - 200px);max-height:calc(100vh - 200px);}*/
.header-logo{width:20%;}
.header-right{width:35%;}
/*.header_sidebar_toggle{padding:13px 30px 13px 0;}*/
.assigned-pages .page-info{max-width:-webkit-calc(100% - 47px);max-width:-moz-calc(100% - 47px);max-width:calc(100% - 47px);}
.assigned-pages .file-img{width:30px;}
/* .assigned-pages .custom-checkbox [type="checkbox"] + label:before{width:31px;height:31px;line-height:31px;}
.assigned-pages .custom-checkbox [type="checkbox"]{width:31px;height:31px;}
.assigned-pages .pages-list .custom-checkbox label{padding-left:45px;} */
.action-row .btn-default{padding:8px 13px 6px;}
.addcontent_modal .assigned-pages .action-row .search-form{width:87%;}
.page-title{font-size:50px;height:50px;}
.content-table .table>tbody>tr>td:nth-child(1), .content-table .table>thead>tr>th:nth-child(1){width:7%;max-width:7%;}
.content-table .table>tbody>tr>td:nth-child(3), .content-table .table>thead>tr>th:nth-child(3){width:31%;max-width:31%;}
/*.search-form.visible_field input{max-width:100%;border-bottom:1px solid #424242;}*/
/*.addcontent_modal .assigned-pages .action-row .search-form,.add_page_modal .assigned-pages .action-row .search-form:hover{width:87%;}*/
}

@media screen and (max-width:991px){
.form-container{margin-top:30px;}
.preview-box{min-height:500px;}
.addcontent_modal .pagination{margin-top:30px;}
.show_upload_img_block{margin-bottom:25px;}
.form-group.img_upload_block + div{clear:both;}
.action_btn_group{display:inline-block;/*width:91%;*/vertical-align:top;}
.action-row .btn-default{font-size:14px;}
.action-row .btn-default{margin:0 8px 0 0;}
.action-row .btn-rounded{margin-top:0;}
}
/*End*/

.lblNoRecord{font-size:16px;padding-top:20px;color:#bbb;}
.content_desc{font-family:'TiemposText';font-size:15px;color:#000;font-weight:normal;margin-top:-10px;margin-bottom:20px;}
.sortable_head > label{color:#bbb;font-size:14px;font-weight:normal;text-transform:uppercase;letter-spacing:1px;float:left;}
.sortable_head_list{float:right;color:#bbb;font-size:14px;text-transform:uppercase;margin:0;padding-bottom:10px !important;}
.sortable_head{float:left;border-bottom:1px solid #bbb;width:100%;margin-top:25px;}
.sortable_head + *{clear:both;}
.sortable_head_list{float:right;}
.sortable_head_list li{display:inline-block;padding:0 4px;text-transform:unset;}
.sortable_head_list li:last-child{padding-right:0;}
.sortable_head_list li:last-child{border-right:none;}
.delete_btn[disabled]{color:#424242;background-color:#fff;border-color:#424242;}
.delete_btn{color:#c90000;background-color:#fff;border-color:#c90000;}
.list_sortable{border-bottom:1px solid #424242;padding:20px 0;position:relative;list-style:none;}
.list_sortable span{font-size:24px;color:#bbbbbb;font-weight:normal;line-height:60px;padding-right:15px;}
.list_sortable .remove-file{vertical-align:top !important;color:#bbbbbb;display:inline-block;font-size:18px;padding-right:10px;padding-left:8px;}
.list_sortable ul{padding:0;}
.list_sortable .file-info{display:inline-block;vertical-align:top !important;font-size:20px;max-width:calc(100% - 70px);float:left;}
.list_sortable .file-progress li{display:inline-block;padding-right:8px;border-right:1px solid #bbb;line-height:1;margin-right:8px;}
.list_sortable .file-progress{padding:0;color:#bbb;font-family:'TiemposText';font-size:14px;}
.list_sortable  .file-progress li:last-child{border-right:none;margin-right:0;}
.list_sortable  .file-name{color:#000000;margin-bottom:0;font-size:15px;line-height:1.1;}
.list_sortable .file-name{font-size:20px;}
.userIconClass{background-color:#bbbbbb;}
.otherIconClass{background-color:#424242;}
.table tbody tr:hover{background-color:#F1F1F1;}
#scrollableDiv ul li, #scrollableDiv ul li *{cursor:pointer;}
#scrollableDiv li:hover{background-color:#F1F1F1;}
.table.hover-row > tbody > tr > td{padding:0px;}
.table.hover-row>tbody>tr>td>a{display:block;padding:10px 15px;}
.table.hover-row>tbody>tr>td:nth-child(2){padding:0px 5px;}
.table.hover-row>tbody>tr>td:nth-child(2) a{padding:0px;}
.truncate{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}
.table.hover-row > tbody > tr > td{cursor:pointer;}
.table.hover-row > tbody > tr > td > div{padding:10px 15px;}
.custom-border{border:10px solid transparent;padding:15px;border-image:url(../border.png) 30% round;}
.bottom-0{padding-bottom:0px;margin-bottom:0px;}
.content-area{padding-bottom:65px;}
.remove-more-btn{font-size:18px;margin-top:27px;cursor:pointer;background:transparent;height:30px;width:30px;vertical-align:middle;border-radius:50%;text-align:center;color:#bbb;}
.remove-more-btn,.remove-more-btn:hover,.remove-more-btn:visited,.remove-more-btn:active{color:#FFF;}
.remove-user-add-row a,.remove-user-add-row a:hover,.remove-user-add-row a:visited,.remove-user-add-row a:active{color:#bbb;}
#scrollableDiv.pages-list ul li label:before{left:10px !important;}
.menu-header-link{display:inline-block !important;border-bottom:none !important;font-size:18px !important;padding:0px !important;}
table thead th{border-bottom:1px solid !important;}
.search-form.search-left input{width:0;min-width:0 !important;}
.search-form.search-left.visible_field input{min-width:100% !important;}
.audio-table{width:100%;}
.action-row .btn-filter{margin:0;}
.audioadd-modal .form-control::-webkit-input-placeholder, .modal-common .form-control::-webkit-input-placeholder{font-size:14px;font-family:'Calibre';}
.audioadd-modal .form-control::-moz-placeholder, .modal-common .form-control::-moz-placeholder{font-size:14px;font-family:'Calibre';}
.audioadd-modal .form-control:-ms-input-placeholder, .modal-common .form-control:-ms-input-placeholder{font-size:14px;font-family:'Calibre';}
.audioadd-modal .form-control:-moz-placeholder, .modal-common .form-control:-moz-placeholder{font-family:'Calibre';font-size:14px;}
.audioadd-modal .form-control:not(select), .modal-common .form-control:not(select){padding:11px 12px 6px;}
.audioadd-modal select, .modal-common  select{padding:9px 12px 6px;}
.audio-icon{width:50px !important;padding-left:10px !important;}
.modal-common .form-group{margin-bottom:25px;}
.audio-table .table.hover-row > tbody > tr > td.actions  .btn-default{padding-top:14px;}
.menu-open .audio-table .table > thead > tr > th.last-modified .th-name{display:inline-block;}
.menu-open .audio-table .table > thead > tr > th.last-modified .th-name::before{bottom:9px;}
_::-webkit-full-page-media, _:future, :root .login_block .form_wrapper .input_field > *:not(.has-error){line-height:normal;}
_::-webkit-full-page-media, _:future, :root .preview-row.no-flex{display:block;}

 /*26-12-19*/
.tab-parent .tab_search_box{float:right;}
.tab-parent .search-form{margin:0;}
.tab_search_box .search-btn{float:right;margin:0;line-height:1;font-size:20px;-webkit-appearance:none;border:none;background:none;box-shadow:none;position:absolute;right:0;top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
.action-row .btn-delete{float:right;border-color:#b90000 !important;color:#b90000 !important;margin-right:0}
.action-row .btn-delete[disabled]:hover{border-color:rgba(185, 0, 0,0.35) !important;}
.user_action_row{position:relative;}
.user_action_row:before, .user_action_row:after{content:"";clear:both;display:table;}
.action-row.mb0{margin-bottom:0}
.content-detail-wrap{margin-top:0}
.sidebar-tab .nav-tabs{padding:0;margin:30px 0;}
.sidebar-tab .nav-tabs > li > a{text-transform:none;padding:0;background:transparent;font-size:17px}
.sidebar-tab .nav-tabs > li > a:after{right:0;left:0;}
.content-detail-wrap .assigned-pages .action-row .search-form{margin:0}
.ub{display:inline-block;padding:2px 20px;-webkit-border-radius:20px;border-radius:20px;background-color:#dedede;height:30px;line-height:30px;vertical-align:middle;color:#000;margin:0px 10px 10px 0px;cursor:pointer;}
.ub.active{background-color:#479edd !important;color:#FFF;}
.bu-table-wrap tr th.custom-checkbox{min-width:73px}
.bu-table-wrap .table tr td{padding:10px !important}
.bu-table-wrap .table tr th{padding-left:10px;padding-right:10px;}
.bu-table-wrap .table tr td.bu-name{font-size:16px;font-weight:bold;color:#000}
.bu-table-wrap .table tr th,.bu-table-wrap .table tr td{height:50px}
.bu-table-wrap .table tr td.bu-status{max-width:120px;min-width:120px;width:120px}
.bu-table-wrap .table tr .actions{text-align:right;max-width:100px;min-width:100px;width:100px}
.bu-table-wrap .table tr .actions .btn-default{padding-right:0}
.bu-table-wrap .table.hover-row > tbody > tr > td > div{padding:0;}
.content-bredcrum-search{position:relative;margin-bottom:30px}
.content-bredcrum-search:before,.content-bredcrum-search:after{content:'';clear:both;display:table;}
.content-bredcrum-search .bredcrum_block{background:transparent;float:left;margin:9px 0 0}
.content-bredcrum-search .bredcrum_block .bredcrum ul{padding:0;text-transform:uppercase;}
.content-bredcrum-search .bredcrum_block .bredcrum ul li{font-size:18px;line-height:22px;}
.content-bredcrum-search .bredcrum_block .bredcrum ul li a{color:#000;display:block;font-size:initial;max-width:350px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}
.content-bredcrum-search .bredcrum_block .bredcrum ul li:after{top:0;right:0;font-size:20px;color:#000;}
.content-bredcrum-search .tab_search_box{float:right;}
.content-bredcrum-search .search-form{float:none;margin:0;}
.content-bredcrum-search .bredcrum_block .bredcrum ul li.active a{color:#009ddc;font-weight:bold;}
.content-bredcrum-search .tab-parent{float:left}
.content-bredcrum-search .tab-parent .nav-tabs{padding:0;margin-bottom:0;}
.table.hover-row tr td.actions .btn-default{padding:6px 0 0;font-size:20px;line-height:20px}
.table.hover-row tr td.content-icon img{max-width:50px;max-height:50px}

/*Detail page*/
.detail-title{position:relative;padding-left:60px;padding-right:60px;margin-bottom:25px;margin-top:30px}
.detail-title .btn-default{position:absolute;left:0;top:0;padding:0;border:none;font-size:13px;line-height:16px;height:16px;bottom:7px;margin:auto}
.detail-title h1{font-size:34px;font-weight:bold;color:#000;margin:0;line-height:38px;text-align:center;}
.main-title{margin-top:20px;margin-bottom:20px}
.main-title h1{font-size:52px;line-height:56px;font-weight:bold;color:#424242;letter-spacing:-0.2px;margin:0}
.tab-content{padding-top:0}
.tab-content .tab-pane{padding-top:0}
.nav.nav-tabs{margin-bottom:30px}
.sidebar-menu .sb-content > ul{padding-left:0}
.sidebar-menu li.parent.no-collaps a{border-bottom:none}
.sidebar-menu .parent span.parent_class{-webkit-transition:all 0.5s ease 0s;transition:all 0.5s ease 0s}
.sidebar-menu .parent span.parent_class.open_menu{margin-bottom:-1px}
.top-header .header-inner,.sidebar .sidebar-top{margin-bottom:0}
.tag-dtl-form-wrap{width:500px}
.title-with-search h1{float:left}
.title-with-search .tab_search_box{float:right}
.title-with-search{position:relative}
.title-with-search:before,.title-with-search:after{content:'';clear:both;display:table;}
.sidebar-menu .parent ul.children{padding-left:50px}
.tag-detail-wrap .nav-tabs > li{margin-right:35px}
.tag-detail-wrap .nav-tabs > li:last-child{margin-right:0}
.tag-detail-wrap .nav-tabs > li a{font-weight:bold;color:#bbb}
.specialities_modal .assigned-pages .pages-list li,.compititors_modal .assigned-pages .pages-list li{padding:13px 0;}
.specialities_modal .assigned-pages .pages-list li .page-info,.compititors_modal .assigned-pages .pages-list li .page-info{padding-left:7px}
.csv-error{margin-top:20px}
.csv-error p{margin-bottom:5px}
.add-to-page-modal .pages-list li{padding:7px 0}
.add-to-page-modal .pages-list .custom-checkbox label{padding-left:45px}

/*Tag Detail*/
.tag-detail-wrap .bu-list-head-action{float:right;}
.tag-detail-wrap .bu-list-head-action .search-form{float:left;padding-right:20px;margin-top:2px;margin-right:5px;border-right:1px solid #bbb;}
.tag-detail-wrap .bu-list-head-action button.btn{border:none;background:transparent;padding:0;font-size:22px;line-height:16px;height:auto;color:#424242;margin:7px 0 0 15px;font-weight:bold;cursor:pointer;opacity:0.3;}
.tag-detail-wrap .bu-list-head-action button.btn.active{opacity:1;}
.tag-detail-wrap .bu-list-head-action button.btn i.la-th-list{padding:0;font-size:24px;line-height:16px;vertical-align:middle;}
.tag-detail-wrap .bu-list-head-action button.btn i.la-th-large{font-size:22px;line-height:16px;padding:0;vertical-align:middle;}
.tag-detail-wrap .bu-list-head-action .search-form .search-btn{right:13px;}
.tag-detail-wrap .bu-list-head-action .search-form input{height:28px}
.list-grid-view ul{font-size:0;margin:30px 4px 0;padding:0;}
.list-grid-view ul li{float:none;display:inline-block;font-size:11px;vertical-align:top;margin:0 0 27px;width:10%;padding:0 8px}
.list-grid-view ul li .grid-view-box{width:100%;height:75px;background-color:transparent;-webkit-border-radius:6px;border-radius:6px;border:1px solid #54575a;color:#54575a;position:relative;text-align:center;}
.list-grid-view .grid-view-box i{line-height:73px;padding-top:0;font-size:45px;text-align:center;display:inline-block;}
.list-grid-view ul li h3{font-size:15px;line-height:20px;text-align:center;margin:0;padding:7px 0 0;color:#000;font-weight:bold;letter-spacing:0.4px;}
.sort-by-row{border-bottom:1px solid #54575a;padding:20px 10px 15px;color:#54575a;font-size:14px;font-weight:normal;text-transform:uppercase;}
.sort-by-row .bottom-border {padding-bottom:5px;position:relative;padding-right:18px;cursor:pointer;color:#54575a;font-size:14px;font-weight:normal;text-transform:uppercase;margin-left:2px;}
.sort-by-row .bottom-border:before {content:'';position:absolute;left:0;right:18px;height:2px;background:#009ddc;bottom:0;margin:auto;}
.sort-by-row .bottom-border:after {content:'';font-family:'FontAwesome';font-size:14px;display:inline-block;margin-left:6px;font-weight:normal;vertical-align:middle;position:absolute;margin-top:-2px;right:0;}
.sort-by-row .bottom-border.active-asc:after {content:'\F0D8';margin-top:-3px;}
.sort-by-row .bottom-border.active-desc:after {content:'\F0D7';}
.compititors-logo-list .grid-view-box i{width:100%;height:100%;background-repeat:no-repeat;background-position:center center;background-size:60px auto}
.icon-arthrex{background-image:url(../images/competitors/icon-arthrex.png);}
.icon-conmed-linvatec{background-image:url(../images/competitors/icon-conmed-linvatec.png);}
.icon-depuy-mitek{background-image:url(../images/competitors/icon-depuy-mitek.png);}
.icon-olympus{background-image:url(../images/competitors/icon-olympus.png);}
.icon-s-n{background-image:url(../images/competitors/icon-s-n.png);}
.icon-steris{background-image:url(../images/competitors/icon-steris.png);}
.icon-storz{background-image:url(../images/competitors/icon-storz.png);}
.bu-table-wrap .table tr td.bu-icon-name{font-size:16px;font-weight:bold;color:#000;vertical-align:middle;}
.bu-table-wrap .table tr td.bu-icon-name i{width:50px;height:50px;background-color:transparent;-webkit-border-radius:6px;border-radius:6px;border:1px solid #54575a;color:#54575a;font-size:30px;line-height:50px;display:inline-block;vertical-align:middle;margin-right:13px;text-align:center;}
.bu-table-wrap .table tr td.compititors-list-icon i{background-repeat:no-repeat;background-position:center center;background-size:30px auto}

/*Talk Tracks*/
.talk-tracks-wrap .tab-pane{padding-top:0}
.talk-tracks-wrap .bu-table-wrap .table tr td{font-family:'TiemposText';font-size:14px}
.talk-tracks-wrap .bu-table-wrap .table tr td a{padding:0}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name{font-size:16px;font-weight:bold;color:#000;vertical-align:middle;position:relative;padding-left:40px !important;font-family:'Calibre';width:65%}
.talk-tracks-wrap .bu-table-wrap .table .tracks-panel-name .panel-arrow-icon{position:absolute;left:11px;top:18px;font-size:16px;color:#54575a}
.talk-tracks-wrap .bu-table-wrap .table .tracks-panel-name .panel-arrow-icon i.la-angle-up{margin-top:1px} 
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data{background:#f1f1f1}
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data td{height:auto;padding:12px 10px 7px !important;vertical-align:top;}
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data td.tracks-panel-name{padding-left:40px !important;font-family:'TiemposText';font-size:14px;font-weight:normal;color:#000}
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data td.tracks-panel-name a{color:#000}
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data td.actions i.la-angle-right{font-size:18px;line-height:18px;color:#424242;margin:-3px 4px 0 0}
.talk-tracks-wrap .bu-table-wrap .table tr .actions{max-width:80px;min-width:80px;width:80px}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name .form-group{margin-bottom:0}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name span + .form-group{margin-top:10px}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name .form-group .form-control{border:none;border-bottom:1px solid transparent;-webkit-border-radius:0;border-radius:0;padding:0;font-size:14px;color:#000;font-family:'TiemposText';-webkit-box-shadow:none;box-shadow:none;height:26px;background:transparent;max-width:650px}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name .form-group .form-control:focus{border-bottom:1px solid #bbb}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name .form-group .form-control::-webkit-input-placeholder{color:#bbb;font-size:14px;opacity:1}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name .form-group .form-control::-moz-placeholder{color:#bbb;font-size:14px;opacity:1}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name .form-group .form-control:-ms-input-placeholder{color:#bbb;font-size:14px;opacity:1}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name .form-group .form-control:-moz-placeholder{color:#bbb;font-size:14px;opacity:1}
.talk-tracks-wrap .bu-table-wrap .table tr td.audio-icon{white-space:nowrap}
.talk-tracks-wrap .bu-table-wrap .table tr td.audio-icon i{font-size:18px;width:24px;height:24px;border: 1px solid #424242;border-radius: 30px;text-align: center;line-height: 22px;color: #424242;outline:none;cursor:pointer;display: inline-block;vertical-align: middle;}
.talk-tracks-wrap .bu-table-wrap .table tr td.audio-icon img.music-img-icon{width:40px;height:40px;vertical-align:middle;margin-left:10px}

/*New Talk Tracks Table*/
.talk-tracks-wrap .bu-table-wrap .table thead tr th:first-child{padding-left:40px !important;}
.talk-tracks-wrap .bu-table-wrap .table thead tr th:first-child,.talk-tracks-wrap .trackaudio-table tr td.trackaudio-table-tr td.tracks-panel-name{width:50%;min-width:50%;max-width:50%}
.talk-tracks-wrap .trackaudio-table table tr{background:#fff}
.talk-tracks-wrap .bu-table-wrap .table thead tr th:nth-child(2),.talk-tracks-wrap .bu-table-wrap .table thead tr th:nth-child(3),.talk-tracks-wrap .trackaudio-table tr td.trackaudio-table-tr .table tr td:nth-child(2),.talk-tracks-wrap .trackaudio-table tr td.trackaudio-table-tr .table tr td:nth-child(3){width:20%;min-width:20%;max-width:20%}
.talk-tracks-wrap .bu-table-wrap .table thead tr th:nth-child(4),.talk-tracks-wrap .trackaudio-table tr td.trackaudio-table-tr .table tr td:nth-child(4){width:10%;min-width:10%;max-width:10%}
.talk-tracks-wrap .trackaudio-table tr td{color:#b1b4b3;padding:14px 10px 8px !important;}
.talk-tracks-wrap .trackaudio-table tr td.trackaudio-table-tr{padding:0 !important}
.talk-tracks-wrap .trackaudio-table tr .trackaudio-table-tr table.table{margin:0}
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data td.tracks-panel-name span.truncate{display:inline-block;max-width:650px}
.talk-tracks-wrap .trackaudio-table tr .trackaudio-table-tr table.table tr:nth-child(2) td{border-top:1px solid #e1e1e1}
.talk-tracks-wrap .audio-action-row .tab_search_box .search-btn{padding-right:0}
.talk-tracks-wrap .audio-action-row .tab_search_box .search-form input{height:30px;padding:5px 25px 5px 0}
.talk-tracks-wrap .trackaudio-table tr .trackaudio-table-tr table.table tr td.actions .btn-default{padding:0;font-size:26px;line-height:26px}

.redux-toastr .toastr .rrt-middle-container{width:calc(100% - 80px);margin-left: 80px;font-family: 'Calibre';font-size: 16px;}
.form-container .form-group .form-control[disabled]{border-bottom-color:#D0D0D0;color:#bbb}
.sidebar-menu li.parent-active .parent_class{color:#009ddc;}
.sidebar-menu li.parent-active .parent_class a{color:#009ddc;}
.btn.loadmore-btn{width: 140px;border-radius: 32px;text-align: center;font-size: 16px;height: 32px;line-height: 24px;text-transform: uppercase;letter-spacing: 0.35px;margin: 20px auto 5px;float: none;display: block;}
.btn.loadmore-btn i {display: inline-block;font-size: 14px;}

.addcontent_modal.add_page_modal.add-to-page-modal .modal-body .assigned-pages{min-height:350px}
.upload-img.custom-border{border:1px dashed #bbb;border-radius: 10px;padding: 20px;margin-bottom:5px}



/*Responsive*/
@media screen and (max-width:1440px){
.list-grid-view ul li{width:14.28571%}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name{width:60%}
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data td.tracks-panel-name span.truncate{max-width:470px}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name .form-group .form-control{max-width:450px}
}

@media screen and (max-width:1024px){
.main-title{margin-top:10px}
.main-title h1{font-size:42px;line-height:46px}
.nav-tabs > li{margin-right:40px}
.content-bredcrum-search .bredcrum_block .bredcrum ul li a{max-width:200px}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name{width:55%}
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data td.tracks-panel-name span.truncate{max-width:380px}
}

@media screen and (max-width:991px){
.detail-title h1{font-size:30px;line-height:36px}
.list-grid-view ul li{width:20%}
.talk-tracks-wrap .bu-table-wrap .table td.tracks-panel-name{width:48%}
.talk-tracks-wrap .bu-table-wrap .table thead tr th:first-child,.talk-tracks-wrap .trackaudio-table tr td.trackaudio-table-tr td.tracks-panel-name{width:40%;min-width:40%;max-width:40%}
.talk-tracks-wrap .bu-table-wrap .table thead tr th:nth-child(2), .talk-tracks-wrap .bu-table-wrap .table thead tr th:nth-child(3), .talk-tracks-wrap .trackaudio-table tr td.trackaudio-table-tr .table tr td:nth-child(2), .talk-tracks-wrap .trackaudio-table tr td.trackaudio-table-tr .table tr td:nth-child(3){width:25%;min-width:25%;max-width:25%}
.talk-tracks-wrap .bu-table-wrap .table tr.tracks-panel-data td.tracks-panel-name span.truncate{max-width:220px}
}
