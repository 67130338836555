.styles_modal__gNwvD{padding:0px !important;}
.modal-dialog{margin:0px !important;}
button.edit-action{background:none;border:none;padding:12px 20px;font-size:15px;line-height:1;color:#000;width:100%;text-align:left;}
button.delete-action{background:none;border:none;padding:12px 20px;font-size:15px;line-height:1;color:#b90000;width:100%;text-align:left;}
button.edit-action:hover, button.delete-action:hover{background-color:#F1F1F1;}
.page-action{background:none;border:none;padding:12px 20px;font-size:15px;line-height:1;color:#000;display:block}
.page-action:hover{background-color:#F1F1F1;color:#000;}
.action-row button{margin-right:15px;}
.action-row button i{padding-right:3px;}
.text-gray{color:#cac8ca !important}
#userTable a{color:#000 !important}
.choose-file{display:inline-block !important}
.has-error{font-size:20px;display:block;color:#F44336;border:1px dashed #000;height:40px;line-height:40px;vertical-align:middle;padding-left:10px;padding-right:10px;margin-top:10px;padding-top:2px;}
.csv-error{color:#F44336;}
.csv-valid{color:#4caf50;}
.csv-error div{font-size:25px;}
.importuser_modal .modal-footer{margin-top:20px;}
.redux-toastr .toastr{background-color:#fcfcfc;width:100%;min-height:70px;overflow:hidden;margin:10px 0;border-radius:2px;position:relative;z-index:2;color:#333;opacity:1 !important;box-shadow:none !important;}
.redux-toastr .toastr .close-toastr{display:none;}
.nav-tabs > li > button{border:none;color:#424242;font-size:16px;letter-spacing:0.8px;text-transform:uppercase;background:none;}
.nav-tabs > li > button:hover{border:none;color:#424242 !important;background:transparent;font-weight:bold;}
.nav-tabs > li > button::after{content:"";background:#009ddc !important;height:3px;position:absolute;/* width:100%; */right:15px;left:15px;bottom:0;-webkit-transition:all 250ms ease 0s;transition:all 250ms ease 0s;-webkit-transform:scale(0);transform:scale(0);}
.table > thead th .th-name:after{content:'';font-family:'FontAwesome';font-size:14px;display:inline-block;margin-left:6px;font-weight:normal;vertical-align:middle;position:absolute;margin-top:-2px;right:0;}
.table > thead th .th-name.active-desc, .table > thead th .th-name.active-asc{cursor:pointer}
.table > thead th .th-name.active-desc:after{content:'\F0D7';}
.table > thead th .th-name.active-asc:after{content:'\F0D8';margin-top:-3px;}
.pages-list{overflow-y:auto;min-height:300px;}
.show_upload_img_block{overflow:hidden !important;text-align:center;}
iframe{width:100% !important;height:100% !important;}
.page-action.edit-action{/*font-size:19px !important;*/}
.search-form-modal{width:auto;}
/*.sticky-footer{display:none !important;}*/
.upload-list{overflow-x:hidden !important;overflow-y:auto !important;height:250px !important;}
.clearfix{display:block;margin-bottom:10px;}
.styles_overlay__CLSq-{overflow-y:scroll !important;}
.modal-footer-inner{margin-top:0px !important;}
.sb-content{padding-bottom:0px !important;}
.loading{z-index:1001;position:fixed;}
.loading + div:after{content:'';position:fixed;top:0;width:100%;height:100%;background:rgba(255,255,255,0);z-index:1001;}
.pages-product-column .align-middle{padding-top:0px;}


